import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Modal,
  Button,
  Card,
  Container,
  Row,
  Col,
  Navbar,
  Nav,
} from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import styled from "styled-components";
import "./App.css";

const API_BASE_URL = "https://api.fastainews.com";
const API_KEY = process.env.REACT_APP_API_KEY;

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "X-API-Key": API_KEY,
  },
});

const fetchNews = async (section = "", offset = 0, limit = 5) => {
  try {
    const response = await api.get(
      `/news?section=${section}&offset=${offset}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching news:", error);
    throw error;
  }
};

const askQuestion = async (newsId, question) => {
  try {
    const response = await api.post("/ask", { news_id: newsId, question });
    return response.data.answer;
  } catch (error) {
    console.error("Error asking question:", error);
    throw error;
  }
};

const fetchLastUpdate = async () => {
  try {
    const response = await api.get("/last_update");
    return response.data.timestamp;
  } catch (error) {
    console.error("Error fetching last update:", error);
    throw error;
  }
};

const NewsModal = ({
  show,
  onHide,
  newsTitle,
  newsSummary,
  newsImage,
  onSubmit,
}) => {
  const [question, setQuestion] = useState("");
  const [conversation, setConversation] = useState([]);
  const [hasAsked, setHasAsked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const modalBodyRef = useRef(null);

  const handleSubmit = async () => {
    if (question.trim() === "" || isLoading) return;

    setIsLoading(true);
    setHasAsked(true);
    const newConversation = [
      ...conversation,
      { sender: "user", text: question },
    ];
    setConversation(newConversation);
    const answer = await onSubmit(question);
    setConversation([...newConversation, { sender: "ai", text: answer }]);
    setQuestion("");
    setIsLoading(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.style.maxHeight = `${window.innerHeight * 0.7}px`;
      modalBodyRef.current.style.overflowY = "auto";
    }
  }, [conversation]);

  useEffect(() => {
    setQuestion("");
    setConversation([]);
    setHasAsked(false);
    setIsLoading(false);
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>질문하기</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={modalBodyRef}>
        <h5>{newsTitle}</h5>
        {!hasAsked && (
          <>
            {newsImage && (
              <img
                src={newsImage}
                alt={newsTitle}
                className="img-fluid mb-3"
                style={{
                  maxHeight: "200px",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            )}
            <div>
              {newsSummary.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < newsSummary.length - 1 && <br />}
                </React.Fragment>
              ))}
            </div>
          </>
        )}
        {hasAsked && (
          <div className="chat-container">
            {conversation.map((message, index) => (
              <div key={index} className={`chat-message ${message.sender}`}>
                <ReactMarkdown>{message.text}</ReactMarkdown>
              </div>
            ))}
            {isLoading && (
              <div className="chat-message ai">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        )}
        <input
          type="text"
          className="form-control mt-3"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="질문을 입력하세요"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? "응답 중..." : "제출"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const NewsCard = ({ item, onAskQuestion }) => {
  const summaryLines = item.summary.split(". ").map((line, index, arr) => {
    if (index === arr.length - 1) {
      return line.trim();
    } else {
      return line.trim() + ".";
    }
  });

  return (
    <Card className="h-100 shadow">
      <Card.Img
        variant="top"
        src={item.image_url}
        style={{ objectFit: "cover", height: "200px" }}
      />
      <Card.Body>
        <Card.Title>{item.title}</Card.Title>
        <Card.Text>
          {summaryLines.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < summaryLines.length - 1 && <br />}
            </React.Fragment>
          ))}
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex justify-content-between align-items-center">
          <small className="text-muted">{item.publisher}</small>
          <div>
            <Button
              className="btn btn-secondary btn-sm me-2"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              원문 보기
            </Button>
            <Button
              className="btn btn-primary btn-sm"
              onClick={() =>
                onAskQuestion(item.id, item.title, summaryLines, item.image_url)
              }
            >
              질문하기
            </Button>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

const LoadMoreCard = ({ onClick }) => (
  <Card className="h-100 shadow load-more-card" onClick={onClick}>
    <Card.Body className="d-flex align-items-center justify-content-center">
      <Card.Title>더 보기</Card.Title>
    </Card.Body>
  </Card>
);

const NewsSection = ({ section, onAskQuestion }) => {
  const [news, setNews] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadMoreNews = useCallback(
    async (reset = false) => {
      if (isLoading) return;
      setIsLoading(true);
      try {
        const currentOffset = reset ? 0 : offset;
        const data = await fetchNews(section, currentOffset);
        setNews((prevNews) =>
          reset ? data.items : [...prevNews, ...data.items]
        );
        setOffset((prevOffset) => (reset ? 5 : prevOffset + 5));
        setHasMore(data.total > (reset ? 5 : offset + 5));
        setError(null);
      } catch (error) {
        setError("뉴스를 불러오는 데 실패했습니다. 다시 시도해 주세요.");
      } finally {
        setIsLoading(false);
      }
    },
    [section, offset, isLoading]
  );

  useEffect(() => {
    setNews([]);
    setOffset(0);
    setHasMore(true);
    loadMoreNews(true);
  }, [section]);

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="my-5">
      <Container>
        <h2 className="mb-4">{section}</h2>
        <Row xs={1} md={2} lg={3} className="g-4">
          {news.map((item) => (
            <Col key={item.id}>
              <NewsCard item={item} onAskQuestion={onAskQuestion} />
            </Col>
          ))}
          {hasMore && (
            <Col>
              <LoadMoreCard onClick={() => loadMoreNews(false)} />
            </Col>
          )}
        </Row>
        {isLoading && <div className="text-center mt-3">Loading...</div>}
      </Container>
    </div>
  );
};

const NavBar = ({ selectedSection }) => {
  const sections = ["전체", "정치", "경제", "사회", "세계", "IT"];

  return (
    <Navbar bg="light" expand="lg" className="navbar-custom">
      <Container>
        <Navbar.Brand as={Link} to="/" className="fw-bold">
          Fast<span className="text-primary">AI</span>News
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {sections.map((section) => (
              <Nav.Link
                key={section}
                as={Link}
                to={`/?section=${section}`}
                className={selectedSection === section ? "active" : ""}
              >
                {section}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
const LastUpdateText = styled.p`
  font-style: italic;
  color: #6c757d;
  margin-bottom: 0;
`;

const Hero = ({ lastUpdate }) => {
  return (
    <div className="bg-light py-3 py-md-4">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={8} className="mb-3 mb-md-0">
            <p className="lead mb-0">
              뉴스, 더 빠르고 스마트하게! 실시간 AI 요약으로 핵심을 놓치지
              마세요.
              <br className="d-none d-md-block" />
              궁금한 점은 AI Assistant가 즉시 답변합니다. 당신만의 24/7 뉴스
              전문가, FastAINews.
            </p>
          </Col>
          <Col md={4} className="text-md-end d-none d-md-block">
            <LastUpdateText>최종 업데이트: {lastUpdate}</LastUpdateText>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const HomePage = () => {
  const [selectedSection, setSelectedSection] = useState("전체");
  const [lastUpdate, setLastUpdate] = useState("");
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [selectedNewsTitle, setSelectedNewsTitle] = useState("");
  const [selectedNewsSummary, setSelectedNewsSummary] = useState([]);
  const [selectedNewsImage, setSelectedNewsImage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionParam = params.get("section");
    if (sectionParam) {
      setSelectedSection(sectionParam);
    } else {
      setSelectedSection("전체");
    }
  }, [location]);

  const updateLastUpdateTime = useCallback(async () => {
    try {
      const timestamp = await fetchLastUpdate();
      if (timestamp) {
        const lastUpdateTime = new Date(timestamp); // 'Z'를 추가하여 UTC 시간으로 처리
        const currentTime = new Date();
        const timeDiff = Math.floor((currentTime - lastUpdateTime) / 1000);

        let relativeTime;
        if (timeDiff < 60) {
          relativeTime = "방금 전";
        } else if (timeDiff < 3600) {
          const minutes = Math.floor(timeDiff / 60);
          relativeTime = `${minutes}분 전`;
        } else if (timeDiff < 86400) {
          const hours = Math.floor(timeDiff / 3600);
          relativeTime = `${hours}시간 전`;
        } else {
          const days = Math.floor(timeDiff / 86400);
          relativeTime = `${days}일 전`;
        }

        setLastUpdate(relativeTime);
      } else {
        setLastUpdate("업데이트 정보 없음");
      }
    } catch (error) {
      console.error("Error fetching last update time:", error);
      setLastUpdate("업데이트 정보를 가져오는 데 실패했습니다");
    }
  }, []);

  useEffect(() => {
    updateLastUpdateTime();
    const intervalId = setInterval(updateLastUpdateTime, 60000); // 매 1분마다 업데이트
    return () => clearInterval(intervalId);
  }, [updateLastUpdateTime]);

  const handleAskQuestion = useCallback(
    (newsId, newsTitle, newsSummary, newsImage) => {
      setSelectedNewsId(newsId);
      setSelectedNewsTitle(newsTitle);
      setSelectedNewsSummary(newsSummary);
      setSelectedNewsImage(newsImage);
      setShowModal(true);
    },
    []
  );

  const handleSubmitQuestion = useCallback(
    async (question) => {
      try {
        const answer = await askQuestion(selectedNewsId, question);
        return answer;
      } catch (error) {
        console.error("Error asking question:", error);
        return "질문 처리 중 오류가 발생했습니다.";
      }
    },
    [selectedNewsId]
  );

  return (
    <>
      <NavBar selectedSection={selectedSection} />
      <Hero lastUpdate={lastUpdate} />
      {selectedSection === "전체" ? (
        ["정치", "경제", "사회", "세계", "IT"].map((section) => (
          <NewsSection
            key={section}
            section={section}
            onAskQuestion={handleAskQuestion}
          />
        ))
      ) : (
        <NewsSection
          section={selectedSection}
          onAskQuestion={handleAskQuestion}
        />
      )}
      <NewsModal
        show={showModal}
        onHide={() => setShowModal(false)}
        newsTitle={selectedNewsTitle}
        newsSummary={selectedNewsSummary}
        newsImage={selectedNewsImage}
        onSubmit={handleSubmitQuestion}
      />
    </>
  );
};

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-3 mt-auto">
      <Container>
        <p className="text-center mb-0">FastAINews &copy; 2024</p>
      </Container>
    </footer>
  );
};

const App = () => {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
